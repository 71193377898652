import './bootstrap';
import 'vue-final-modal/style.css';
import '../css/index.css';
import { errorHandler } from '@/util/error-handler';
import { sentryError } from '@/util/sentry-error';
import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import Toast, { PluginOptions } from 'vue-toastification';
import { createPinia } from 'pinia';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import Vapor from 'laravel-vapor';
import AppLayout from '@/layout/AppLayout.vue';
import { getRoute } from '@/util/route';
import BaseLayout from '@/layout/BaseLayout.vue';
import { vfm } from '@/plugins/vue-final-modal';
import { Analytics } from '@/util/posthog';

try {
  window.Vapor = Vapor;
  window.Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
} catch (__) {
  window.Vapor = null;
}

// let scrollTimeout: NodeJS.Timeout | null = null;

// const onScroll = (e: Event) => {
//   const target = e.target as HTMLElement;
//   if (target.classList?.contains('on-scrollbar') === false && target.classList?.contains('force-scrollbar') === false) {
//     target.classList.add('on-scrollbar');
//   }
//   if (scrollTimeout !== null) {
//     clearTimeout(scrollTimeout);
//   }
//   scrollTimeout = setTimeout(() => {
//     if (target.classList?.contains('on-scrollbar') === true) {
//       target.classList?.remove('on-scrollbar');
//     }
//   }, 1000);
// };

// if (window.innerWidth > 768) {
//   window.addEventListener('scroll', onScroll, true);
// }

// toast options
const options: PluginOptions = {
  timeout: 2500,
  pauseOnFocusLoss: false,
  hideProgressBar: true,
};

Analytics.init('phc_QNnPrcP4nuBiedAgaUVLsR7WzBxUy9gDcDZ6RyKPLMR');

createInertiaApp({
  progress: false,
  resolve: async (name) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const page = (await resolvePageComponent(`./pages/${name}.vue`, import.meta.glob('./pages/**/*.vue'))).default;

    if (page.layout === undefined && !name.startsWith('Auth/') && !name.startsWith('public-pages/')) {
      page.layout = AppLayout;
    } else if (name.startsWith('public-pages/')) {
      page.layout = BaseLayout;
    }

    return page;
  },
  setup({ el, App, props, plugin }) {
    const VueApp = createApp({
      // eslint-disable-next-line vue/component-api-style
      render: () => h(App, props),
    });
    if (import.meta.env.MODE === 'development') {
      VueApp.config.performance = true;
    } else {
      errorHandler(VueApp);
      sentryError(VueApp);
    }
    try {
      VueApp.config.globalProperties.$asset = window.Vapor.asset;
    } catch (__) {
      VueApp.config.globalProperties.$asset = (value: string) => `/${value}`;
    }

    VueApp.config.globalProperties.$route = getRoute;
    VueApp.use(plugin)
      .use(Toast, options)
      .use(createPinia())
      .use(vfm)
      .directive('focus', (elem) => elem.focus())
      .component('InertiaLink', Link)
      .mount(el);
  },
}).then();
