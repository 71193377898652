import moment from 'moment';
import {
  dateFormat,
  dateTimeFormat,
  humanReadableDateFormat,
  humanReadableDateFormatShort,
  humanReadableDateWithYearFormat,
  timeFormat,
} from '@/variables/date-format';
import { inject } from 'vue';
import { endKey, startKey } from '@/provide/keys';
import { getKey } from '@/util/globals';

export const formatStampAsDate = (date: string | null = null, format = dateFormat, defaultReturn = ''): string => {
  if (!date) {
    return defaultReturn;
  }
  return moment(date).format(format);
};

export const formatStampAsDateTime = (dateTime = '', format = dateTimeFormat, defaultReturn = '') => {
  if (!dateTime) {
    return defaultReturn;
  }
  return moment(dateTime).format(format);
};

export function changeAndFormatStamp({
  stamp = null,
  format = dateTimeFormat,
  defaultReturn = '',
  startOf = null,
  endOf = null,
  addMinutes = null,
  subMinutes = null,
  addDays = null,
  subDays = null,
  addSeconds = null,
  subSeconds = null,
}) {
  if (!stamp) {
    return defaultReturn;
  }
  let momentStamp = moment(stamp);

  if (startOf !== null) {
    momentStamp = momentStamp.startOf(startOf);
  }
  if (endOf !== null) {
    momentStamp = momentStamp.endOf(endOf);
  }
  if (addMinutes !== null) {
    momentStamp = momentStamp.add(addMinutes, 'minutes');
  }
  if (addDays !== null) {
    momentStamp = momentStamp.add(addDays, 'days');
  }
  if (addSeconds !== null) {
    momentStamp = momentStamp.add(addSeconds, 'seconds');
  }
  if (subMinutes !== null) {
    momentStamp = momentStamp.subtract(subMinutes, 'minutes');
  }
  if (subDays !== null) {
    momentStamp = momentStamp.subtract(subDays, 'days');
  }
  if (subSeconds !== null) {
    momentStamp = momentStamp.subtract(subSeconds, 'seconds');
  }
  return momentStamp.format(format || dateTimeFormat);
}

export const formatStampAsHumanReadableDate = (
  dateTime: string | null = null,
  format = humanReadableDateFormat,
  defaultReturn = ''
) => {
  if (!dateTime) {
    return defaultReturn;
  }
  return moment(dateTime).format(format);
};

export function formatStampAsTime(time = null, otherTime = null, format = timeFormat) {
  if (!time) {
    return '';
  }

  if (otherTime) {
    const diff = Math.abs(moment(time).diff(otherTime, 'hours'));
    if (diff > 20) {
      return moment(time).format(format) + ` (+${diff}H)`;
    }
  }

  return moment(time).format(format);
}

export const formatStampAsHumanReadableDateTime = (dateTime: string | null = null, withYear?: boolean = false) => {
  if (!dateTime) {
    return '';
  }
  return moment(dateTime).format(
    `${withYear ? humanReadableDateWithYearFormat : humanReadableDateFormat} ${timeFormat}`
  );
};

export function formatStampAsHumanReadableDateTimeShort(dateTime = null) {
  if (!dateTime) {
    return '';
  }
  return moment(dateTime).format(`${humanReadableDateFormatShort} ${timeFormat}`);
}

export const timeStampsAreSame = (
  first: string | null = null,
  second: string | null = null,
  granularity: string | undefined = 'day'
) => {
  if (first === null || second === null) {
    return false;
  }
  return moment(first).isSame(second, granularity);
};

/*
  IntervalStart: Start of outer bounds
  IntervalEnd: End of outer bounds
  ObjectStart: Start of what to find if inside or not
  ObjectEnd: end of what to find if inside or not
 */
export function isWithinIntervalOfObject(intervalStart, intervalEnd, objectStart, objectEnd, strict = false) {
  if (strict) {
    return moment(objectStart).isSameOrAfter(intervalStart) && moment(objectEnd).isSameOrBefore(intervalEnd);
  }
  return (
    (moment(objectStart).isSameOrBefore(intervalStart) && moment(objectEnd).isSameOrAfter(intervalStart)) ||
    (moment(objectStart).isSameOrAfter(intervalStart) && moment(objectEnd).isSameOrBefore(intervalEnd)) ||
    (moment(objectStart).isSameOrBefore(intervalStart) &&
      moment(objectEnd).isSameOrAfter(intervalStart) &&
      moment(objectEnd).isSameOrBefore(intervalEnd)) ||
    (moment(objectStart).isSameOrAfter(intervalStart) &&
      moment(objectStart).isSameOrBefore(intervalEnd) &&
      moment(objectEnd).isSameOrAfter(intervalEnd))
  );
}

/*
  IntervalStart: Start of outer bounds
  IntervalEnd: End of outer bounds
 */
export function isNowInInterval(intervalStart, intervalEnd) {
  return (
    moment(intervalStart).isSameOrBefore(moment(), 'second') && moment(intervalEnd).isSameOrAfter(moment(), 'second')
  );
}

/*
  IntervalStart: Start of outer bounds
  IntervalEnd: End of outer bounds
 */
export const timestampInInterval = (timestamp: string, intervalStart: string, intervalEnd: string) => {
  return (
    moment(intervalStart).isSameOrBefore(moment(timestamp), 'second') &&
    moment(intervalEnd).isSameOrAfter(moment(timestamp), 'second')
  );
};

export function formatMinutesAsHoursAndMinutes(minutes = 0) {
  if (isNaN(minutes)) {
    return '00:00';
  }
  let hour = Math.floor(minutes / 60);
  let min = minutes - hour * 60;

  if (hour < 10 && hour >= 0) {
    hour = `0${hour}`;
  }
  min = Math.floor(min);
  if (min < 10 && min >= 0) {
    min = `0${min}`;
  }
  return `${hour}:${min}`;
}

export function formatSecondsAsHoursAndMinutesAndSeconds(
  seconds = 0,
  showHoursIfNull = true,
  roundDown24Hours = false
) {
  if (isNaN(seconds)) {
    return '00:00';
  }

  let hour = Math.floor(seconds / (60 * 60));
  let min = Math.floor((seconds - hour * 60 * 60) / 60);
  let sec = seconds - hour * 60 * 60 - min * 60;
  if (min < 10 && min >= 0) {
    min = `0${min}`;
  }
  if (sec < 10 && sec >= 0) {
    sec = `0${sec}`;
  }

  if (roundDown24Hours && hour >= 24) {
    hour -= 24 * Math.floor(hour / 24);
  }

  if ((showHoursIfNull && hour >= 0) || (!showHoursIfNull && hour > 0)) {
    if (hour < 10) {
      hour = `0${hour}`;
    }
    return `${hour}:${min}:${sec}`;
  }

  return `${min}:${sec}`;
}

export const getDiffInInterval = (
  start: string | null,
  end: string | null,
  unitOfTime = 'minutes',
  absolute = true
) => {
  if (start === null || end === null) {
    return 0;
  }
  return absolute ? Math.abs(moment(start).diff(end, unitOfTime)) : moment(start).diff(end, unitOfTime);
};

export function isSameOrBefore(first, second) {
  if (!first || !second) {
    return false;
  }
  return moment(first).isSameOrBefore(second);
}

export const isSameOrAfter = (first, second) => {
  if (!first || !second) {
    return false;
  }
  return moment(first).isSameOrAfter(second);
};

export const getNow = (
  format: null | string = null,
  startOf: null | string = null,
  endOf: null | string = null,
  dateTime: null | string = null
) => {
  const localFormat = format !== null ? format : dateTimeFormat;
  if (startOf !== null) {
    return moment(dateTime).startOf(startOf).format(localFormat);
  }
  if (endOf !== null) {
    return moment(dateTime).endOf(endOf).format(localFormat);
  }
  return dateTime === null ? moment().format(localFormat) : moment(dateTime).format(localFormat);
};

export function stampIsValid(stamp = null) {
  if (!stamp) {
    return false;
  }
  return moment(stamp).isValid();
}

export function formattedStartAndEndAsInterval(start = null, end = null, date = null) {
  if (start === null && end === null) {
    return 'N/A';
  }
  let string = '';
  if (date) {
    if (!moment(start).isSame(date, 'day')) {
      string = `${moment(start).format(dateFormat)} at `;
    }
  }
  if (!end) {
    return `${string + moment(start).format('HH:mm')}`;
  }
  const diff = Math.abs(moment(start).diff(end, 'hours'));
  if (diff < 20) {
    return `${string + moment(start).format('HH:mm')} - ${moment(end).format(timeFormat)}`;
  }
  return `${string + moment(start).format('HH:mm')} - ${moment(end).format(timeFormat)}(+${diff}H)`;
}

export function formatStartAndEndAsDates(start = null, end = null, roundOfSameDay = true) {
  if (!start || !end) return '';
  if (moment(start).isSame(moment(end).subtract(roundOfSameDay ? 6 : 0, 'hours'), 'day')) {
    return `${moment(start).format('dddd MMMM Do YYYY')}`;
  }
  if (moment(start).isSame(end, 'month')) {
    return ` ${moment(start).format('dddd Do')} - ${moment(end).format('dddd Do [of] MMMM YYYY')}`;
  }
  return ` ${moment(start).format('dddd MMMM Do')} - ${moment(end).format('dddd MMMM Do YYYY')}`;
}

export function formatHoursAndMinutesAsMinutes(time = null) {
  if (!time) {
    return 0;
  }
  const durationSplit = time.split(/[:]+/);
  return Number(durationSplit[0]) * 60 + Number(durationSplit[1]);
}

export function formatHoursAndMinutesAndSecondsAsSeconds(time = null) {
  if (!time) {
    return 0;
  }
  const durationSplit = time.split(/[:]+/);
  return Number(durationSplit[0]) * 60 * 60 + Number(durationSplit[1]) * 60 + Number(durationSplit[2]);
}

export function minutesToTime(minutes) {
  if (!minutes) return 0;
  return moment.utc().startOf('day').add(minutes, 'minutes').format('hh:mm');
}

export function humanReadableMinutes(minutes) {
  if (!minutes) return 0;
  return moment.duration(minutes, 'minutes').humanize();
}

export function dateIsToday(date) {
  if (!date) return false;
  return moment(date).isSame(moment(), 'day');
}

export function generateArrayByWeek(array) {
  return Object.values(
    array
      .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
      .reduce((acc, date) => {
        // create a composed key: 'year-week'
        const yearWeek = `${moment(date.date).year()}-${moment(date.date).isoWeek()}`;
        // add this key as a property to the result object
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }
        // push the current date that belongs to the year-week calculated before
        acc[yearWeek].push(date);
        return acc;
        // }, {});
      }, {})
  ).filter((val) => val);
}

export function generateArrayOfDatesBetween(date1 = null, date2 = null, format = null, granularity = 'day') {
  if (!date1 || !date2) return [];
  // const dates = [];
  const dateArray = [];
  let currentDate = moment(date1);
  const stopDate = moment(date2);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(format || dateFormat));
    currentDate = moment(currentDate).add(1, granularity);
  }
  return dateArray;
}

export const highlightDatesForKeys = () => {
  const start = inject(startKey, null);
  const end = inject(endKey, null);
  return generateArrayOfDatesBetween(start, end);
};

export const momentIsAfter = (
  dateTime: moment.MomentInput,
  hoursBeforeCheckinAllowed: moment.DurationInputArg1 = 2
) => {
  if (!dateTime) {
    return false;
  }
  return moment().isAfter(moment(dateTime).subtract(hoursBeforeCheckinAllowed, 'hours'));
};

export const stampIsPast = (stamp: string | null = null, granularity: moment.unitOfTime.StartOf = 'minutes') => {
  if (!stamp) {
    return false;
  }
  return moment(stamp).isBefore(moment(), granularity);
};

export const stampIsBefore = (dateTime: moment.MomentInput = null, otherDateTime: moment.MomentInput = null) => {
  if (!dateTime || !otherDateTime) {
    return false;
  }
  return moment(dateTime).isSameOrBefore(moment(otherDateTime));
};

export function stampIsAfter(dateTime = null, otherDateTime = null, includeSame = false) {
  if (!dateTime || !otherDateTime) {
    return false;
  }
  if (includeSame) {
    return moment(dateTime).isSameOrAfter(moment(otherDateTime));
  }
  return moment(dateTime).isAfter(moment(otherDateTime));
}

export const stampIsFuture = (stamp: string | null = null, granularity: moment.unitOfTime.StartOf = 'minutes') => {
  if (!stamp) return false;
  return moment(stamp).isAfter(moment(), granularity);
};

export function compareTwo(a, b, attribute, asc = true) {
  if (!attribute || !a || !b) {
    return 0;
  }
  const first = asc ? a : b;
  const second = asc ? b : a;

  if (first[attribute] < second[attribute]) {
    return -1;
  }
  if (first[attribute] > second[attribute]) {
    return 1;
  }
  return 0;
}

export function diffForHumans(first, second = null) {
  if (second !== null) {
    return moment.duration(moment(first).diff(moment(second))).humanize(true);
  }
  return moment.duration(moment(first).diff(moment())).humanize(true);
}

export const groupByDate = <T>(items: T[], attribute: string, format = null) => {
  return _.groupBy(items, (item) => moment(item[attribute]).format(format !== null ? format : dateFormat));
};

export function sortByAttribute(items, attribute = null, asc = true) {
  return items.sort((a, b) => {
    const first = asc ? a : b;
    const second = asc ? b : a;

    return (
      moment(attribute !== null ? first[attribute] : first).valueOf() -
      moment(attribute !== null ? second[attribute] : second).valueOf()
    );
  });
}

export function formatDurationAsMinutes(value: number) {
  let minutes = value;
  const hour = Math.floor(minutes / 60);
  minutes -= hour * 60;
  let text = '';
  if (hour > 0) {
    text = `${text + hour} hours`;
    if (minutes > 0) {
      text = `${text} & ${minutes} minutes`;
    }
    return text;
  }
  return `${minutes} minutes`;
}

export const itemIsOngoing = (item, startKey = 'start', endKey = 'end') => {
  return (
    moment(getKey(item, startKey)).isSameOrBefore(moment(), 'day') &&
    moment(getKey(item, endKey)).isSameOrAfter(moment(), 'day')
  );
};

export const diffInHoursForDisplay = (start, end) => {
  if (!start || !end) return '';

  const diffInMinutes = getDiffInInterval(start, end, 'minutes');
  const hours = Math.floor(diffInMinutes / 60);
  const minutes = diffInMinutes % 60;

  if (minutes === 0) {
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  }
  return `${hours > 0 ? hours + ' h ' : ''}${minutes} min`;
};
