import { acceptHMRUpdate, defineStore } from 'pinia';
import type { PageTab, SubMenuTab } from '@/types/page-tab';
import { computed, nextTick, ref } from 'vue';
import { updateQueryString } from '@/util/query-helpers';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { DropDownListItem } from '@/components/Inputs/Dropdown/VDropdown.vue';

export const usePageTabsStore = defineStore('page-tabs', () => {
  const tabs = ref<PageTab[]>([]);
  const inNavbar = ref(true);
  const activeTabId = ref<string | null>(null);
  const activeSubTabId = ref<string | null>(null);
  const addTabItems = ref<DropDownListItem[]>([]);

  const activeTab = computed(() => tabs.value.find((tab: PageTab) => tab.key === activeTabId.value));
  const activeSubTab = computed(() =>
    activeTab.value?.tabs?.find((tab: SubMenuTab) => tab.key === activeSubTabId.value)
  );

  const reset = () => {
    tabs.value = [];
    inNavbar.value = true;
    activeTabId.value = null;
    activeSubTabId.value = null;
  };

  const toggleSection = (tabKey: string) => {
    const index = tabs.value.findIndex((t) => t.key === tabKey);
    if (index === -1) return;

    tabs.value[index].open = !tabs.value[index].open;
  };

  const setActiveTab = (tab: PageTab) => {
    if (!tab) return;

    // Deselect all other tabs
    tabs.value.forEach((t) => (t.active = t.key === tab.key));

    activeTabId.value = tab.key;

    if (tab) {
      if ('open' in tab) {
        tab.open = true;
      }
      if (tab.parentId) {
        const parentTab = tabs.value.find((t) => t.key === tab.parentId);
        if (parentTab) {
          parentTab.open = true;
          parentTab.active = true;
        }
      }
    }

    updateHash(tab.key);
  };

  const setActiveSubTab = (tab: SubMenuTab) => {
    activeSubTabId.value = tab.key;
    const currentHash = window.location.hash.replace('#', '').split('/')[0];
    updateHash(`${currentHash}/${tab.key}`);
  };

  const updateHash = (hashValue: string | null) => {
    window.location.hash = hashValue ? `#${hashValue}` : '';
  };

  const goBack = () => {
    const item = getItemFromArrayBasedOnId(activeTab.value?.key, tabs.value, null, 'key');
    if (!item) return;
    const parentId = getKey(item, 'parentId');
    const parentIndex = _.findIndex(tabs.value, (i) => i.key === parentId);
    if (parentIndex !== -1) {
      setActiveTab(tabs.value[parentIndex]);
    }
  };

  const changeTab = (tabName: string, queryString: string | null = null) => {
    const tab = tabs.value.find((i) => i.key === tabName);
    if (tab) {
      setActiveTab(tab);

      if (queryString) {
        nextTick(() => {
          updateQueryString(queryString, true);
        });
      }
    }
  };

  const changeSubTab = (tabName: string, queryString: string | null = null) => {
    const tab = activeTab?.value?.tabs?.find((i) => i.key === tabName);
    if (tab) {
      setActiveSubTab(tab);

      if (queryString) {
        nextTick(() => {
          updateQueryString(queryString, true);
        });
      }
    }
  };

  const initialize = (initialTabs: PageTab[]) => {
    if (!initialTabs.length) return;

    tabs.value = initialTabs;

    const hashParts = window.location.hash?.replace('#', '').split('/');
    const mainHash = hashParts[0];
    const subHash = hashParts[1];

    const matchingTab = initialTabs.find((t) => t.key === mainHash) || initialTabs[0];
    setActiveTab(matchingTab);

    if (subHash && matchingTab.tabs) {
      const matchingSubTab = matchingTab.tabs.find((t) => t.key === subHash);
      if (matchingSubTab) {
        setActiveSubTab(matchingSubTab);
      }
    }
  };

  return {
    tabs,
    inNavbar,
    activeTab,
    activeSubTab,
    initialize,
    reset,
    setActiveTab,
    setActiveSubTab,
    goBack,
    changeTab,
    changeSubTab,
    toggleSection,
    activeTabId,
    addTabItems,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePageTabsStore, import.meta.hot));
}
