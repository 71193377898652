<script lang="ts" setup>
import { nextTick, onBeforeUnmount, ref, useTemplateRef } from 'vue';
import CheckBox from '@/components/Icons/CheckBox.vue';
import { getKey } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';
import { highlightStringBySearch } from '@/util/text-replace-helper';
import { Link } from '@inertiajs/vue3';

type DataAtrributes = {
  [key: string]: string;
};

type ListSubItem = {
  title?: string;
  value?: string | number;
  preIcon?: string;
  postIcon?:
    | string
    | {
        type: 'icon' | 'button';
        icon?: string;
        text?: string;
        action: (close: () => void) => void;
        onlyHover?: boolean;
      };
  type?: 'header' | 'divider';
  action?: () => void;
  disabled?: boolean;
  class?: string;
  selected?: boolean;
  hoverTitle?: string;
  dataAttribute?: DataAtrributes;
};

export type ListItem = ListSubItem & {
  items?: ListSubItem[];
  subListLeft?: boolean;
};

export type Items = ListItem[];

type Props = {
  items: ListItem[];
  highlightText?: null;
  withArrowsUpAndDown?: boolean;
};

const props = defineProps<Props>();

const subList = useTemplateRef<HTMLUListElement>('subList');

const subItemLeft = ref(0);
const subItemTop = ref(0);
const showSubList = ref(false);

let closeTimer: ReturnType<typeof setTimeout> | null = null;

const onMouseEnter = async (event: MouseEvent, item: ListItem) => {
  if (!item.items?.length && !subList.value) return;

  await nextTick();

  const listItem = document.getElementById(`sub-list-${item.value}`);

  if (!listItem) return;

  let maybeTop = event.target.getBoundingClientRect().top - 3;

  const viewportHeight = window.innerHeight;

  const submenuHeight = subList.value[0]?.offsetHeight;

  if (maybeTop + submenuHeight > viewportHeight) {
    maybeTop = viewportHeight - submenuHeight - 10; // 10px padding
  }

  subItemTop.value = maybeTop;

  if (!item.subListLeft) {
    subItemLeft.value = event.target.getBoundingClientRect().right;
  } else {
    subItemLeft.value = event.target.getBoundingClientRect().left - listItem.getBoundingClientRect().width;
  }

  if (closeTimer) {
    clearTimeout(closeTimer);
    closeTimer = null;
  }

  showSubList.value = true;
};

const onMouseLeave = (item: ListItem, mouseEvent: MouseEvent) => {
  if (!item.items?.length) return;

  closeTimer = setTimeout(() => {
    showSubList.value = false;
    subItemLeft.value = 0;
    subItemTop.value = 0;
    closeTimer = null;
  }, 400);
};

const focusIndex = ref(null);

const scrollToItem = () => {
  window.requestAnimationFrame(() => {
    const element = document.querySelector(`[data-id="v-list-index-${focusIndex.value}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  });
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'ArrowDown') {
    e.preventDefault();
    if (focusIndex.value === null) {
      focusIndex.value = 0;
      return;
    }
    if (focusIndex.value >= props.items.length) {
      focusIndex.value = null;
    } else {
      focusIndex.value++;

      if (props.items[focusIndex.value].type === 'divider' || props.items[focusIndex.value].type === 'header') {
        onKeyDown(e);
        // focusIndex.value++;
      }
    }
    scrollToItem();
    return;
  }
  if (e.key === 'ArrowUp') {
    e.preventDefault();
    if (focusIndex.value === null) {
      focusIndex.value = props.items.length - 1;
    } else if (focusIndex.value === 0) {
      focusIndex.value = null;
    } else {
      focusIndex.value--;
      if (props.items[focusIndex.value].type === 'divider' || props.items[focusIndex.value].type === 'header') {
        onKeyDown(e);
      }
    }

    scrollToItem();
    return;
  }
  if (e.key === 'Enter') {
    e.preventDefault();
    if (!focusIndex.value) return;
    const currentItem = props.items[focusIndex.value];
    const action = getKey(currentItem, 'action');
    if (action) {
      action();
    }
  }
  focusIndex.value = null;
};

if (props.withArrowsUpAndDown) {
  window.addEventListener('keydown', onKeyDown);

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', onKeyDown);
  });
}

const onItemClick = (item: ListItem, clickEvent: MouseEvent) => {
  if (!item?.action || item.disabled) return;

  item.action(item, clickEvent);
};
const getProps = (item: ListItem) => {
  if (item.link) {
    return {
      href: getKey(item, 'link'),
    };
  }
  return {};
};
</script>

<template>
  <ul
    class="space-y-edge-1/4 px-edge-1/4 py-edge-1/4 text"
    role="list">
    <template
      v-for="(item, index) in items"
      :key="item.value">
      <hr
        v-if="item.type === 'divider' && index < items.length - 1"
        class="-mx-edge-1/4"
        :class="item.class" />
      <li
        v-else-if="item.type !== 'divider'"
        :aria-disabled="item.disabled"
        v-bind="item.dataAttribute"
        :class="[
          { 'hover:bg-row-hover': item.type !== 'header' && !item.disabled },
          { 'bg-row-hover': item.type !== 'header' && !item.disabled && index === focusIndex },
          { 'cursor-pointer': item.action && !item.disabled },
          { 'cursor-not-allowed opacity-50': item.disabled },
          {
            '-mx-edge-1/4 -mt-edge-1/4 rounded-b-none bg-[--color-background-modal-header] px-edge-1/2 pt-edge-1/2':
              item.type === 'header',
          },
          item.class,
        ]"
        :data-id="`v-list-index-${index}`"
        :title="getKey(item, 'hoverTitle', item.title)"
        class="group flex items-center gap-edge-1/2 rounded px-edge-1/2 py-edge-1/4"
        @mouseenter="onMouseEnter($event, item)"
        @mouseleave="onMouseLeave(item, $event)"
        @click="onItemClick(item, $event)">
        <component
          :is="getKey(item, 'link') ? Link : 'div'"
          v-bind="getProps(item)"
          class="flex w-full items-center gap-edge-1/2">
          <div v-if="item.preIcon || $slots.pre || item.selected !== undefined">
            <slot
              :item="item"
              name="pre">
              <CheckBox
                v-if="item.selected !== undefined"
                :model-value="item.selected" />
              <i
                v-else-if="item.preIcon"
                :class="item.preIcon"
                class="fa fa-fw" />
            </slot>
          </div>
          <div
            :class="[{ 'text-left font-headers font-semibold': item.type === 'header' }]"
            class="flex-1 font-headers"
            v-html="highlightStringBySearch(getKey(item, 'title', ''), item.type === 'header' ? '' : highlightText)" />

          <i
            v-if="focusIndex === index"
            class="fa fa-fw fa-arrow-left" />
          <i
            v-else-if="item.postIcon && typeof item.postIcon === 'string'"
            :class="item.postIcon"
            class="fa fa-fw" />

          <i
            v-else-if="getKey(item, 'items', []).length > 0"
            class="fa fa-fw fa-chevron-right text-soft" />

          <i
            v-else-if="item.postIcon && typeof item.postIcon === 'object' && item.postIcon.type === 'icon'"
            :class="[item.postIcon, { 'invisible hover:visible': item.postIcon.onlyHover }]"
            class="fa fa-fw"
            @click="item.postIcon.action()" />

          <VButton
            v-else-if="item.postIcon && typeof item.postIcon === 'object' && item.postIcon.type === 'button'"
            :class="{ 'opacity-0 group-hover:!opacity-100': item.postIcon.onlyHover }"
            type="default"
            size="xs"
            :icon="item.postIcon.icon"
            :title="item.postIcon.text"
            @click="item.postIcon.action()" />
          <div
            v-else-if="withArrowsUpAndDown"
            class="fa-fw" />
          <ul
            v-if="item.items?.length"
            :id="`sub-list-${item.value}`"
            ref="subList"
            :style="`left: ${subItemLeft}px; top: ${subItemTop}px;`"
            :class="showSubList ? 'visible' : 'invisible'"
            class="fixed max-h-[80vh] overflow-auto rounded border bg-content">
            <template
              v-for="(subItem, subIndex) in item.items"
              :key="`${item.value}_${subItem.value}`">
              <hr v-if="subItem.type === 'divider' && subIndex < item.items.length - 1" />
              <li
                v-else-if="subItem.type !== 'divider'"
                :aria-disabled="subItem.disabled"
                :class="[
                  { 'hover:bg-row-hover': subItem.type !== 'header' && !subItem.disabled },
                  { 'cursor-pointer': subItem.action && !subItem.disabled },
                  { 'cursor-not-allowed opacity-50': subItem.disabled },
                  subItem.class,
                ]"
                :title="getKey(subItem, 'hoverTitle')"
                class="group flex items-center gap-edge-1/2 rounded p-edge-1/2"
                @click="subItem.action && !subItem.disabled ? subItem.action() : undefined">
                <div v-if="subItem.preIcon || $slots.pre || subItem.selected">
                  <slot
                    :item="subItem"
                    name="pre">
                    <CheckBox
                      v-if="subItem.selected !== undefined"
                      :model-value="subItem.selected" />
                    <i
                      v-else-if="subItem.preIcon"
                      :class="subItem.preIcon"
                      class="fa fa-fw" />
                  </slot>
                </div>
                <div
                  :class="{ 'text-left font-semibold': subItem.type === 'header' }"
                  class="flex-1"
                  v-html="highlightStringBySearch(subItem.title, highlightText)" />

                <i
                  v-if="subItem.postIcon && typeof subItem.postIcon === 'string'"
                  :class="subItem.postIcon"
                  class="fa fa-fw" />

                <!--              {{ subItem.postIcon }}-->

                <i
                  v-if="subItem.postIcon && typeof subItem.postIcon === 'object' && subItem.postIcon.type === 'icon'"
                  :class="[subItem.postIcon, { 'invisible hover:visible': subItem.postIcon.onlyHover }]"
                  class="fa fa-fw"
                  @click="subItem.postIcon.action()" />

                <VButton
                  v-if="subItem.postIcon && typeof subItem.postIcon === 'object' && subItem.postIcon.type === 'button'"
                  :class="{ 'invisible hover:visible': subItem.postIcon.onlyHover }"
                  type="default"
                  size="sm"
                  :icon="subItem.postIcon.icon"
                  :title="subItem.postIcon.text"
                  @click="subItem.postIcon.action()" />
              </li>
            </template>
          </ul>
        </component>
      </li>
    </template>
  </ul>
</template>
