<script setup lang="ts">
import { ref } from 'vue';
import { WhisperObject } from '@/util/whisper-functions';
import { BoardRowResource } from '@/types/board';
import { createUuId, getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import VTable, { SortEmit } from '@/components/Tables/VTable.vue';
import { copyObject } from '@/util/object-helpers';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { getComponent } from '@/util/get-component';
import { allAvailableFieldTypes } from '@/util/fields';
import VButton from '@/components/Inputs/VButton.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';

export type FieldTableColumnObject = {
  id: string;
  title: string | null;
  component: string;
};

export type FieldTableRowObject = {
  id: string;
  columns: {
    id: string;
    value: string | null | boolean;
  }[];
};

type Props = {
  modelValue?: [] | string | null;
  canEdit: boolean;
  isHidden?: boolean;
  editContent?: boolean;
  editForm?: boolean;
  whisperChannel?: string | null;
  whisperString?: string | null;
  placeholder?: string | null;
  whisper?: WhisperObject | null;
  options?: FieldTableColumnObject[];
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  isHidden: false,
  editContent: false,
  editForm: false,
  whisperChannel: null,
  whisperString: null,
  placeholder: null,
  whisper: null,
  options: () => [],
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: [] | null): void;
  (event: 'blur', value: string | boolean): void;
}>();

const { isSmallScreen } = useSmallScreen();

const focusingMyself = ref(false);
const focusedBySomeone = ref(null);
const newlyCreatedRowUuid = ref(null);

const emitValue = (value) => {
  if (!props.canEdit) return;
  emit('update:modelValue', JSON.stringify(value));
};
const emitBlur = (value) => {
  if (!props.canEdit) return;
  emit('blur', JSON.stringify(value));
  focusingMyself.value = false;
};

const sortedRows = ref<FieldTableRowObject[]>([]);
const newOrderOfRows = async (e: SortEmit) => {
  const { selectedItem: idOfMovedRow, newOrder: arrayOfIds } = e;
  let localRows = copyObject(sortedRows.value);
  const newLocalRows = arrayOfIds.map((id) => getItemFromArrayBasedOnId(id, [...localRows]));
  emit('blur', JSON.stringify(localRows));
  sortedRows.value = newLocalRows;
};

const addRow = async () => {
  const uuid = createUuId('table-row-');
  let localRows = copyObject(sortedRows.value);
  localRows.push({
    id: uuid,
  });
  emit('update:modelValue', JSON.stringify(localRows));
  sortedRows.value = localRows;
  newlyCreatedRowUuid.value = uuid;
};

const getColumnValue = (option: FieldTableColumnObject, row: object) => {
  const index = getIndexFromArrayBasedOnId(row.id, sortedRows.value);
  if (index === -1) {
    return null;
  }
  const columnIndex = getIndexFromArrayBasedOnId(option.id, getKey(sortedRows.value[index], 'columns'));
  if (columnIndex > -1) {
    return sortedRows.value[index].columns[columnIndex].value;
  }
  return null;
};

const getProps = (field: FieldTableColumnObject) => {
  const defaultProps = {
    canEdit: props.editContent,
  };

  const leftIcon = getItemFromArrayBasedOnId(
    field.component,
    [...allAvailableFieldTypes],
    { icon: null },
    'component'
  ).icon;
  switch (field.component) {
    case 'field-text': {
      return {
        ...defaultProps,
        'is-hidden': true,
        'min-height': 42,
        'min-rows': 1,
        'whisper':
          props.whisperChannel && props.whisperString
            ? { channel: props.whisperChannel, string: props.whisperString + field.id }
            : null,
        'icon-left': leftIcon,
      };
    }
    case 'field-date': {
      return {
        'is-hidden': true,
        ...defaultProps,
        'mode': getKey(field, 'mode'),
      };
    }
    case 'field-number': {
      return {
        ...defaultProps,
        withDecimals: true,
        'is-hidden': true,
        'icon-left': leftIcon,
        'size': 'block',
      };
    }

    case 'field-toggle': {
      return {
        ...defaultProps,
        'class': 'min-w-[80px]',
      };
    }

    case 'field-time': {
      return {
        ...defaultProps,
        'is-hidden': true,
      };
    }

    default: {
      return defaultProps;
    }
  }
};

const hydrate = () => {
  if (props.modelValue) {
    sortedRows.value = typeof props.modelValue === 'string' ? JSON.parse(props.modelValue) : props.modelValue;
    return;
  }
  if (props.editForm && !props.editContent) {
    sortedRows.value.push({});
  }
};
hydrate();

const assignValue = (value: any, option: FieldTableColumnObject, row: FieldTableRowObject) => {
  let localRows = copyObject(sortedRows.value);
  const index = getIndexFromArrayBasedOnId(row.id, localRows);
  if (index === -1) {
    return;
  }
  if (!getKey(localRows[index], 'columns')) {
    localRows[index].columns = [];
  }
  const columnIndex = getIndexFromArrayBasedOnId(option.id, getKey(localRows[index], 'columns'));
  if (columnIndex > -1) {
    if (localRows[index].columns[columnIndex].value === value) {
      return;
    }
    localRows[index].columns[columnIndex].value = value;
  } else {
    localRows[index].columns.push({
      id: option.id,
      value: value,
    });
  }
  emit('blur', JSON.stringify(localRows));
  sortedRows.value = localRows;
};

const deleteRow = (row: FieldTableRowObject) => {
  let localRows = copyObject(sortedRows.value);
  const index = getIndexFromArrayBasedOnId(row.id, localRows);
  if (index === -1) {
    return;
  }
  localRows.splice(index, 1);
  emit('blur', JSON.stringify(localRows));
  sortedRows.value = localRows;
};
</script>

<template>
  <div class="overflow-x-auto">
    <VTable
      v-if="!isSmallScreen"
      v-model="sortedRows"
      handle-outside
      :vertically-bordered-table="true"
      :bordered-table="true"
      :can-drag="canEdit && editContent && !editForm"
      row-size="small"
      @sorted="newOrderOfRows">
      <template #head>
        <VTableRow
          head
          class="[&_th]:px-3">
          <VTableCell
            v-if="canEdit"
            style="min-width: 40px; max-width: 40px; width: 40px"></VTableCell>
          <VTableCell v-for="option in options">
            {{ option.title }}
          </VTableCell>
          <VTableCell
            v-if="canEdit"
            style="min-width: 60px; max-width: 60px; width: 60px" />
        </VTableRow>
      </template>

      <template #row="{ item: row, index }: { item: BoardRowResource }">
        <VTableCell
          v-if="canEdit"
          main-cell
          clickable>
          <div>
            <i class="fa fa-fw fa-arrows-up-down"></i>
          </div>
        </VTableCell>
        <VTableCell
          v-for="(option, index) in options"
          has-input
          style="min-width: 100px"
          main-cell>
          <component
            :is="getComponent(option.component)"
            :model-value="getColumnValue(option, row)"
            :set-focus="newlyCreatedRowUuid === row.id && index === 0"
            v-bind="getProps(option)"
            @blur="assignValue($event, option, row)" />
        </VTableCell>
        <VTableCell
          v-if="canEdit"
          main-cell
          style="min-width: 60px; max-width: 60px; width: 60px">
          <VButton
            size="sm"
            icon="fa-trash fa-regular"
            @click="deleteRow(row)"></VButton>
        </VTableCell>
      </template>

      <template
        v-if="canEdit"
        #footer>
        <VTableRow no-background>
          <VTableCell></VTableCell>
          <VTableCell
            clickable
            :colspan="options.length"
            classes="relative group/button left-[40px] z-[9] sticky  [&>*.left-side-border]:block [&>*.right-side-border]:block">
            <div
              class="text-headers hover:text-textColor gap-2 flex h-[40px] items-center p-edge text-sm hover:bg-row-hover"
              @click="addRow()">
              <i class="fa fa-fw fa-plus text-highlight"></i>
              Add row
            </div>
          </VTableCell>
          <VTableCell></VTableCell>
        </VTableRow>
      </template>
    </VTable>
    <div
      v-if="isSmallScreen"
      class="flex flex-col gap-edge">
      <BoxContainer
        v-for="(row, rowIndex) in sortedRows"
        :key="row.id"
        :title="'Item #' + (rowIndex + 1)"
        :actions="[
          {
            title: 'Delete',
            icon: 'fa-trash fa-regular',
            action: () => {
              deleteRow(row);
            },
          },
        ]">
        <div class="flex flex-col gap-edge">
          <div
            v-for="(option, index) in options"
            :key="option?.id">
            <InputLabel :label="option.title" />
            <component
              :is="getComponent(option.component)"
              :model-value="getColumnValue(option, row)"
              :set-focus="newlyCreatedRowUuid === row.id && index === 0"
              v-bind="getProps(option)"
              @blur="assignValue($event, option, row)" />
          </div>
        </div>
      </BoxContainer>

      <VButton
        title="Add"
        type="primary"
        icon="fa-plus"
        @click="addRow()" />
    </div>
  </div>
</template>
